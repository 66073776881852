.info {
    grid-area: info;
    background: white;
    display: flex;

    & .rounds {
        height: fit-content;
        width: fit-content;
        margin: auto;
    }

    & .round {
        display: flex;
        flex-direction: row;

        & .icon {
            width: auto;
            height: 1.25rem;
            margin-left: 0.5rem;
        }
    }
}
