button {
    cursor: pointer;
    background-color: var(--gold);
    border: none;
    color: var(--white);
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 94% 6% 92% 8% / 9% 93% 7% 91%;
    box-shadow: 5px 4px 0px 0px var(--brown);
    transition: box-shadow 0.2s;

    &:active {
        box-shadow: 0px 0px 0px 0px var(--brown);
    }
}

input {
    border: 2px solid var(--brown);
    border-radius: 3% 97% 2% 98% / 97% 3% 97% 3%;
    outline: none;
    background: var(--input);
    padding: 0.5rem 1rem;
    &::placeholder {
        font-style: italic;
        color: var(--white);
    }
}

::-webkit-scrollbar-track {
    background-color: rgb(58, 53, 53);
    border-radius: 2rem;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--white);
    border-radius: 2rem;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(194, 189, 189);
    border-radius: 2rem;
}
