.stella {
    .steps {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .step {
            position: absolute;
            width: calc(1vh + 1vw + 10%);
            height: calc(1vh + 1vw + 4%);
            &:nth-child(1) {
                bottom: 3%;
                left: 20%;
            }
            &:nth-child(2) {
                bottom: 12%;
                left: 50%;
            }
            &:nth-child(3) {
                bottom: 20%;
                left: 30%;
            }
            &:nth-child(4) {
                bottom: 30%;
                left: 52%;
            }
            &:nth-child(5) {
                bottom: 40%;
                left: 20%;
            }
            &:nth-child(6) {
                bottom: 50%;
                left: 75%;
            }
            &:nth-child(7) {
                bottom: 62%;
                left: 8%;
            }
            &:nth-child(8) {
                bottom: 70%;
                left: 50%;
            }
            &:nth-child(9) {
                bottom: 80%;
                left: 72%;
            }
            &:nth-child(10) {
                bottom: 88%;
                left: 20%;
            }
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #ddd;
                border-radius: 50%;
                border: 0.25em solid #ccc;
                mix-blend-mode: color-burn;
                z-index: 1;
            }
            &::after {
                content: attr(data-step);
                position: absolute;
                background-image: url("../../../assets/images/stella/step.png");
                background-size: contain;
                background-repeat: no-repeat;
                top: 70%;
                left: 70%;
                width: calc(0.75vw + 0.75vh + 5%);
                height: calc(0.75vw + 0.75vh + 2.5%);
                font-size: calc(0.4vw + 0.4vh);
                z-index: 3;
                text-align: center;
                padding: 0.35em;
            }
            .player-coin {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background-color: var(--player-color);
                border-radius: 50%;
                img {
                    width: 100%;
                    height: 100%;
                    mix-blend-mode: overlay;
                }
            }
        }
    }
}
