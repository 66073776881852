.stella {
  .board {
    grid-area: board;
    display: grid;
    grid-template-columns: 20em calc(100% - 20em);
    grid-template-rows: 100%;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    padding: 1rem;
    .background {
      position: relative;
      width: fit-content;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom left;
      }    
    }
    .anchor {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      .cards-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        width: fit-content;
        max-width: 100%;
        height: 90%;
        padding: 1rem;
        align-items: center;
        margin: 0 auto auto auto;
        .card {
          position: relative;
          width: 80%;
          height: calc(100% - 2rem);
          border-radius: 1rem;
          padding: 0.5rem;
          display: flex;
          overflow: hidden;

          &.highlighted {
            animation: highlight-card 1s linear infinite;
            @keyframes highlight-card {
              0% {
                filter: brightness(1);
              }
              50% {
                filter: brightness(1.75);
              }
              100% {
                filter: brightness(1);
              }
            }
          }
          &.selected::before {
            content: "";
            position: absolute;
            height: 200%;
            width: 400%;
            top: 50%;
            left: 50%;
            background: conic-gradient(#b88a44, #d68c02, #ffe078, #ca9126, #edc967, #ffdf88, #e0aa3e, #b88a44);
            animation: border-rotate 6s linear infinite;
            @keyframes border-rotate {
              0% {
                transform: translateX(-50%) translateY(-50%) rotate(0deg);
              }
              100% {
                transform: translateX(-50%) translateY(-50%) rotate(360deg);
              }
            }
          }

          &.greyed {
            img {
              opacity: 0.1;
              filter: grayscale(1);
            }
          }

          img {
            margin: auto;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.5em;
            z-index: 1;
          }

          .players {
            position: absolute;
            top: 1em;
            left: 1em;
            display: flex;
            flex-direction: column;
            gap: 0.25em;
            font-weight: bold;
            font-size: 1.5em;
          }
        }
      }
      button.ready {
        font-size: 1rem;
        width: fit-content;
        margin: auto;
      
        cursor: pointer;
        background-color: var(--gold);
        border: none;
        color: var(--white);
        font-weight: bold;
        padding: 0.5rem 1rem;
        border-radius: 94% 6% 92% 8% / 9% 93% 7% 91%;
        box-shadow: 5px 4px 0px 0px var(--white);
        transition: box-shadow 0.2s;
      
        &:active {
          box-shadow: 0px 0px 0px 0px var(--white);
        }
      }
    }
  }
}
