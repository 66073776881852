.clash-card {
    height: 16rem;
    max-height: 18vw;
    width: 12rem;
    max-width: 13vw;

    & .content {
        position: relative;
        height: 100%;
        width: 100%;
        transition: transform 0.5s;
        perspective: 2000px;
        transform-style: preserve-3d;

        & .top,
        .bottom {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            user-select: none;
            pointer-events: none;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        & .bottom {
            opacity: 0;
        }
    }

    &.fight {
        animation: card-fight 2s;

        @keyframes card-fight {
            0% {
                transform: translateY(0);
            }
            25% {
                transform: translateY(-3rem);
            }
            75% {
                transform: translateY(-3rem);
            }
            100% {
                transform: translateY(0);
            }
        }
    }

    &.ripped {
        & .top {
            animation: card-ripped-top 2s forwards;

            @keyframes card-ripped-top {
                25% {
                    clip-path: url(#paper);
                }
                100% {
                    clip-path: url(#paper);
                }
            }
        }
        & .bottom {
            opacity: 1;
            animation: card-ripped-bottom 2s forwards;

            @keyframes card-ripped-bottom {
                0% {
                    transform: translateY(0) rotate(0deg);
                }
                25% {
                    transform: translateY(0) rotate(0deg);
                    clip-path: url(#paper-alternative);
                }
                100% {
                    transform: translateY(3rem) rotate(35deg);
                    clip-path: url(#paper-alternative);
                }
            }
        }
    }

    & .info {
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
        background: transparent;
        filter: drop-shadow(1px 1px 2px black);
    }
}

.other-player {
    & .fight {
        animation: other-card-fight 2s;

        @keyframes other-card-fight {
            0% {
                transform: translateY(0);
            }
            25% {
                transform: translateY(3rem);
            }
            75% {
                transform: translateY(3rem);
            }
            100% {
                transform: translateY(0);
            }
        }
    }
}
