.ui-container {
    .users {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: var(--white);
        .player {
            position: relative;
            &.active:after {
                content: "★";
                position: absolute;
                top: 0;
                right: -1rem;
                color: var(--white);
            }
        }
    }
    .actions {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        background: var(--white);
    }
}
