.field {
    grid-area: field;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1rem;
    touch-action: none;

    & .cards {
        background: orange;
        display: flex;
    }
}

.other-palyer {
}
