.stella {
  .word {
    grid-area: word;
    text-align: center;
    h2 {
      font-size: 3rem;
      margin: 0;
      text-transform: uppercase;
      color: var(--gold);
    }
    span {
      color: var(--white);
      opacity: 0.75;
    }
  }
}