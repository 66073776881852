.game {
    width: 100%;
    height: 50rem;
    max-height: 100vh;
    margin: auto;
    background-color: #333;

    display: grid;
    grid-template-areas:
        "other-hand other-hand other-hand other-hand"
        "info field field deck"
        "info field field deck"
        "hand hand hand hand";
    grid-template-columns: 1fr 2fr 2fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;

    position: relative;

    & .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }
}
