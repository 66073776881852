.hand {
    grid-area: hand;
    background: red;
    display: flex;
    justify-content: center;

    perspective: 2000px;
    transform-style: preserve-3d;
    touch-action: none;
}

.other-player {
    &.hand {
        grid-area: other-hand;
        transform: scaleY(-1);
    }
}
