.stella {
  .rounds-anchor {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: var(--gold);
    z-index: 5;
    width: 1rem;
    padding: 0.25rem;
    display: flex;
    background: var(--white);
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;

    svg {
      height: 5rem;
      width: auto;
      margin: auto;
    }
  }
  .rounds {
    position: relative;
    grid-area: rounds;
    background: url("../../../assets/images/stella/background.jpeg");
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 1.5em;
    // background-color: pink;
    &-info {
      position: absolute;
      left: -2em;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      background: var(--black);
      padding: 0.75em;
      border-radius: 2.5em;
      gap: 0.5em;

      .coin {
        position: relative;
        width: 4em;
        height: 4em;

        &.flip {
          transition: 0.5s linear;
          perspective: 1200px;
          transform-style: preserve-3d;
          transform: rotateY(180deg);
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--black);
          backface-visibility: hidden;

          &:nth-child(2) {
            transform: rotateY(180deg);
          }
        }
      }
    }

    .steps {
      .step {
        position: absolute;
        width: 4em;
        height: 4em;
        &:nth-child(1) {
          bottom: 3em;
          left: 4em;
        }
        &:nth-child(2) {
          bottom: 7em;
          left: 12em;
        }
        &:nth-child(3) {
          bottom: 12em;
          left: 8em;
        }
        &:nth-child(4) {
          bottom: 19em;
          left: 13em;
        }
        &:nth-child(5) {
          bottom: 24em;
          left: 6em;
        }
        &:nth-child(6) {
          bottom: 28em;
          left: 15em;
        }
        &:nth-child(7) {
          bottom: 33em;
          left: 4em;
        }
        &:nth-child(8) {
          bottom: 37em;
          left: 10em;
        }
        &:nth-child(9) {
          bottom: 41em;
          left: 1em;
        }
        &:nth-child(10) {
          bottom: 46em;
          left: 4em;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #ddd;
          border-radius: 50%;
          border: 0.25em solid #ccc;
          mix-blend-mode: color-burn;
          z-index: 1;
        }
        &::after {
          content: attr(data-step);
          position: absolute;
          background-image: url("../../../assets/images/stella/step.png");
          background-size: contain;
          background-repeat: no-repeat;
          top: 3em;
          left: 3em;
          width: 2em;
          height: 2em;
          z-index: 3;
          text-align: center;
          padding: 0.35em;
          font-size: 0.8em;
        }
        .player-coin {
          position: absolute;
          top: 0;
          left: 0;
          width: 4em;
          height: 4em;
          z-index: 2;
          background-color: var(--player-color);
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            mix-blend-mode: overlay;
          }
        }
      }
    }
  }
}
