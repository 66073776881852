@use "../mixins.scss";

.stella {
  display: grid;
  grid-template-areas:
    "word word word"
    "board board chat"
    "leaderboard info chat";
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: 6rem calc(80% - 6rem) 20%;
  width: 100%;
  height: 100%;
}
