.card {
    height: 10rem;
    max-height: 10vw;
    width: 7rem;
    max-width: 8vw;
    z-index: 1;
    perspective: 2000px;
    transform-style: preserve-3d;

    /* Support div used for grabbing cards */
    &-handler {
        z-index: 10;
        touch-action: none;
    }

    & .content {
        position: relative;
        height: 100%;
        width: 100%;
        transition: transform 0.5s;
        perspective: 2000px;
        transform-style: preserve-3d;

        &.flipped {
            transform: rotateY(180deg);
        }

        & .front,
        .back {
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            user-select: none;
            pointer-events: none;
        }
        & .back {
            transform: rotateY(180deg) translateZ(1px);
            background: radial-gradient(#313233, #1e1e1e);
            border-radius: 8px;
            border: 0.15rem solid #ff9f00;
            // box-shadow: inset 2px 2px 0px 0px #1e1e1e, inset 4px 4px 0px 0px #ff9f00;
            padding: 0.15rem;

            & .inner {
                border-radius: 6px;
                border: 0.15rem solid #ff9f00;
                height: calc(100% - 0.25rem);
            }
        }
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

/* Specific style for cards in hand */
.hand {
    & .card {
        margin-left: -6rem;
        transform-origin: 50% 300%;
        transition: transform 0.5s;
    }

    /* Specific style for cards in player hand */
    &.player {
        .content:hover {
            transform: translateY(-15px) translateZ(10px);
        }
    }
}

/* Specific style for cards in field */
.field {
    & .card {
        margin: auto 0;
    }
}

/* Specific style for cards in deck's pile */
.deck {
    & .card-handler {
        position: absolute;
        bottom: 0;
        left: 0;
        box-shadow: -2px 2px 2px 0px #000000a0;

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                margin-left: #{$i * 0.2}rem;
                margin-bottom: #{$i * 0.2}rem;
                z-index: #{$i};
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            box-shadow: inset -5px 5px 15px 0px #ffffffd0;
        }
    }
}
