.stella {
  .leaderboard {
    grid-area: leaderboard;
    // background-color: green;
    height: calc(100% - 4rem);
    width: calc(100% - 4rem);
    padding: 1rem;
    .players {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 1rem;
      overflow: auto;
      background-color: var(--white);
      border-radius: 1% 2% 1% 45% / 51% 94% 96% 3%;
      box-shadow: 10px 10px 0px 0px var(--gray);
      .player {
        display: grid;
        grid-template-columns: 2rem calc(100% - 7.5rem) 2rem 2rem;
        gap: 0.5rem;
        .icon {
          width: 1.5rem;
          height: auto;
        }
        span {
          margin: auto 0;
        }
      }
    }
  }
}
