@import "./styles/font.scss";

:root {
  --yellow: #fae610;
  --gold: #e0aa3e;
  --input: #ebb23f;
  --brown: #4a1818;
  --black: #191a1b;
  --gray: #363636;
  --white: #f3f3f3;
  --green: #358600;
}
html {
  // font-size: 1vw;
  overflow: hidden;
  touch-action: none;
  transition: font-size 0.2s;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Patrick Hand", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--black);
  touch-action: none;
}
body :not(input) {
  user-select: none;
}
input {
  user-select: auto;
}
*::after,
*::before {
  box-sizing: border-box;
  user-select: none;
}
img {
  pointer-events: none;
  user-select: none;
  touch-action: none;
}
