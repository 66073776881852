.clash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 5rem;
    touch-action: none;
    pointer-events: none;

    // background: #fff;
    z-index: 100;
    padding: 5rem;

    & .other-player {
        & .clash-card {
            margin-top: auto;
        }
    }

    & .cards {
        background: orange;
        display: flex;
    }

    & .masks {
        max-height: 0;
        overflow: hidden;
    }
}
