.stella {
  .chat {
    grid-area: chat;
    display: flex;
    flex-direction: column;
    height: calc(100% - 2rem);
    padding: 1rem;
    .messages {
      height: 100%;
      flex-grow: 0;
      flex-shrink: 1;
      padding: 1rem;
      background-color: var(--white);
      border-radius: 99% 1% 99% 1% / 1% 99% 1% 99%;
      box-shadow: 10px 10px 0px 0px var(--gray);
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      overflow: auto;
      
      .message {
        width: 75%;
        margin-right: auto;
        &.mine {
          margin-right: unset;
          margin-left: auto;
          h3 {
            display: none;
          }
          .text {
            background: var(--player-color);
            color: var(--player-text-color);
          }
        }
        h3 {
          border-left: 3px solid var(--player-color);
          font-weight: gold;
          font-size: 1.1rem;
          color: var(--player-color);
          padding-left: 0.5rem;
          margin: 0.5rem 0;
        }
        .text {
          padding: 1rem;
          border-radius: 50rem 1rem 65rem 1rem / 1.75rem 20rem 2rem 26rem;
          background: var(--black);
          color: var(--white);
          font-size: 0.75rem;
          white-space: pre-wrap;
        }
      }
    }
    .textfield {
      height: fit-content;
      flex-shrink: 0;
      padding: 1rem;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      .input {
        width: calc(100% - 2.5rem);
        height: fit-content;
        max-height: 100%;
        overflow: auto;
        flex-grow: 0;
        flex-shrink: 1;
        margin-top: auto;
        background-color: #fff;
        border-radius: 1rem;
        white-space: pre-wrap;
        user-select: auto;
        padding: 0 1rem;
      }
      .icon {
        width: 2rem;
        flex-shrink: 0;
        height: auto;
        color: var(--white);
        margin-top: auto;
        margin-bottom: -0.25rem; // Center icon
      }
    }
  }
}
