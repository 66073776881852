.lobby {
  margin: auto;
  background: var(--white);
  padding: 2rem;
  border-radius: 4% 96% 5% 95% / 93% 3% 97% 7%;
  box-shadow: 10px 10px 0px 0px #383838;
  max-height: 80vh;
  width: 80%;
  max-width: 35rem;
  display: flex;
  flex-direction: column;

  .form {
    display: flex;
    gap: 1.5rem;
  }

  .lobbies {
    height: 100%;
    overflow: auto;
    .list {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 1rem;
      .row {
        display: grid;
        grid-template-columns: 1fr 9fr 3fr;
        // display: flex;
        // justify-content: space-between;
        .green {
          --dot-primary: #228400;
          --dot-secondary: #81e700;
        }
        .red {
          --dot-primary: #840000;
          --dot-secondary: #e76f00;
        }
      }
    }
  }

  .buttons {
    display: flex;
    gap: 1.5rem;
  }
}

.user {
  position: relative;
  &.my-user {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      border: 8px solid transparent;
      border-right-color: var(--black);
    }
  }
  &.host {
    &:before {
      content: "★";
      position: absolute;
      top: 0;
      left: -1rem;
    }
  }
  &.ready {
    &:before {
      content: "✓";
      position: absolute;
      top: 0;
      left: -1rem;
    }
  }
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  .card {
    display: flex;
    position: relative;
    border: 3px solid transparent;
    span {
      position: absolute;
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      text-align: center;
      background: var(--yellow);
      font-weight: bold;
    }
    img {
      width: 14rem;
    }
    &.selected {
      border: 3px solid var(--yellow);
    }

    .players {
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      .player {
        background-color: var(--white);
        padding: 0.25rem;
      }
    }
  }
}
