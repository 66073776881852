.deck {
    grid-area: deck;
    background: gold;
    width: 100%;
    height: 100%;
    display: flex;

    & .cards {
        height: 10rem;
        max-height: 10vw;
        width: 7rem;
        max-width: 8vw;
        margin: auto;
        position: relative;
    }
}
