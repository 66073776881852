.stella {
  .info {
    grid-area: info;
    height: calc(100% - 4rem);
    width: calc(100% - 4rem);
    padding: 1rem;
    .notifications {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      overflow: auto;
      background-color: var(--white);
      border-radius: 1% 99% 1% 99% / 97% 2% 98% 3%;
      box-shadow: 10px 10px 0px 0px var(--gray);
      padding: 1rem;
      .notification {
        color: --notification-color;
      }
    }
  }
}
